import { gsap } from 'gsap';

// interface Refs {
//   view: HTMLDivElement;
//   text: HTMLElement;
//   gallery: HTMLElement;
// }

interface AnimationItem {
  el: HTMLImageElement | Element;
  start: number;
  y: number;
  height: number;
  ease: string | null;
  delay: number | null;
  timing: number | null;
  count: string | null;
  measure: string | null;
  type?: string;
  origin?: string;
  children?: string;
  done: boolean;
}

interface ParallaxItem {
  el: HTMLImageElement | Element;
  start: number;
  y: number;
  height: number;
  horizontal: boolean;
  type: string | null;
  shift: number | string | null;
  done: boolean;
}

interface Cache {
  animations?: AnimationItem[];
  parallaxes?: ParallaxItem[];
}

export default class Scroller {
  view: HTMLElement;

  cache: Cache;

  storePosition: number;

  isScrollAnimating: boolean;

  ignoreCache?: boolean;

  constructor(view: HTMLElement) {
    this.view = view;
    this.cache = {};
    this.storePosition = 0;
    this.isScrollAnimating = false;
    // this.onScrollTextBound = this.onScrollText.bind(this);
  }

  setAnimation = (element: HTMLElement, dataset: any) => {
    if (dataset.animation === 'curtain') {
      const curtain = document.createElement('span');
      curtain.classList.add('curtain');
      element.appendChild(curtain);
    }

    return ({
      el: element,
      start: 0.1,
      y: element.getBoundingClientRect().top + window.scrollY,
      height: element.clientHeight,
      done: element.classList.contains('is-passed'),
      ease: dataset.ease || null,
      delay: dataset.delay ? Number(dataset.delay) : null,
      timing: dataset.timing ? Number(dataset.timing) : null,
      count: dataset.count || null,
      measure: dataset.measure || '',
      type: dataset.animation,
      origin: dataset.origin,
    });
  };

  storeCache() {
    const container = this.view || document;
    const animations: AnimationItem[] = [];
    const parallaxes: ParallaxItem[] = [];
    const animationElements = container.querySelectorAll('[data-animation]');
    const parallaxElements = container.querySelectorAll('[data-parallax]');

    animationElements.forEach(el => {
      const element = el as HTMLElement;
      const { dataset = {} } = element;

      if (dataset.children) {
        // gsap.set(el, { opacity: 1 });
        const children = el.querySelectorAll(dataset.children);
        children.forEach(child => {
          // gsap.set(child, { opacity: 0 });
          animations.push(this.setAnimation(child as HTMLElement, dataset));
        });
      } else {
        animations.push(this.setAnimation(element, dataset));
      }
    });

    parallaxElements.forEach(el => {
      const element = el as HTMLElement;
      const { dataset = {} } = element;
      let { parallax } = element.dataset as { parallax: string | number };
      const { horizontal, start } = dataset;
      const parallaxNumber = Number(parallax);
      const offset = el.getBoundingClientRect();

      // eslint-disable-next-line no-restricted-globals
      parallax = isNaN(parallaxNumber) ? parallax : parallaxNumber;

      parallaxes.push({
        el,
        start: start ? Number(start) : 0,
        y: offset.top + window.scrollY,
        height: element.getBoundingClientRect().height,
        horizontal: !!horizontal,
        // height: element.clientHeight,
        type: typeof parallax === 'string' ? parallax : null,
        shift: typeof parallax === 'number' ? parallax : null,
        done: false,
      });
    });

    this.cache.animations = animations;
    this.cache.parallaxes = parallaxes;
  }

  onScroll = () => {
    this.onScrollAnim();
    this.onScrollParallax();
  };

  onScrollAnim = () => {
    const h = window.innerHeight;
    const doc = document.documentElement;
    const st = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (this.cache.animations && this.cache.animations.length > 0) {
      const { animations } = this.cache;
      for (let i = 0; i < animations.length; i += 1) {
        const item = animations[i];
        const itemY = this.ignoreCache ? item.el.getBoundingClientRect().top : item.y;
        const yBottom = st + ((1 - item.start) * h);
        const itemHeight = this.ignoreCache ? item.el.clientHeight : item.height;

        if (!item.done && itemY <= yBottom && itemY + itemHeight >= st) {
          item.el.classList.add('is-passed');
          this.animation(item);
          item.done = true;
        }
      }
    }
  };

  onScrollParallax = () => {
    const h = window.innerHeight;
    const doc = document.documentElement;
    const st = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (this.cache.parallaxes && this.cache.parallaxes.length > 0) {
      const { parallaxes } = this.cache;
      for (let i = 0; i < parallaxes.length; i += 1) {
        this.parallax(parallaxes[i], st, h);
      }
    }
  };

  animation = (item: AnimationItem) => {
    const timing = item.timing || 0.6;
    const delay = item.delay || 0;
    const ease = item.ease || 'sine.out';
    const count = item.count || 0;
    const countValue = { value: 0 };

    switch (item.type) {
      case 'fadeIn':
        gsap.killTweensOf(item.el, { opacity: true });

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            opacity: 0,
          },
          {
            duration: timing,
            opacity: 1,
            ease: 'circ.inOut',
            delay,
          },
        );

        break;

      case 'fadeUp':
        gsap.killTweensOf(item.el, { opacity: true, y: true });

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            opacity: 0,
            y: 40,
          },
          {
            duration: timing,
            opacity: 1,
            y: 0,
            ease,
            delay,
          },
        );

        break;

      case 'fadeDown':
        gsap.killTweensOf(item.el, { opacity: true, y: true });

        gsap.fromTo(
          item.el,
          {
            duation: timing,
            opacity: 0,
            y: -40,
          },
          {
            duation: timing,
            opacity: 1,
            y: 0,
            ease,
            delay,
          },
        );

        break;

      case 'fadeRight':
        gsap.killTweensOf(item.el, { opacity: true, x: true });

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            opacity: 0,
            x: 40,
          },
          {
            duration: timing,
            opacity: 1,
            x: 0,
            ease,
            delay,
          },
        );

        break;

      case 'fadeLeft':
        gsap.killTweensOf(item.el, { opacity: true, x: true });

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            opacity: 0,
            x: -40,
          },
          {
            duration: timing,
            opacity: 1,
            x: 0,
            ease,
            delay,
          },
        );

        break;

      case 'scale':
        gsap.killTweensOf(item.el, { opacity: true, y: true });

        if (item.origin) {
          gsap.set(item.el, { transformOrigin: item.origin });
        }

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            scale: 0,
            opacity: 0,
          },
          {
            duration: timing,
            scale: 1,
            opacity: 1,
            ease,
            delay,
          },
        );

        break;

      case 'scaleY':
        gsap.killTweensOf(item.el, { opacity: true, scaleY: true });
        gsap.set(item.el, { transformOrigin: item.origin || 'top' });
        gsap.fromTo(
          item.el,
          {
            duration: timing,
            scaleY: 0,
            opacity: 0,
          },
          {
            duration: timing,
            scaleY: 1,
            opacity: 1,
            ease: 'back.out(1.7)',
            delay,
          },
        );

        break;

      case 'scaleX':
        gsap.killTweensOf(item.el, { opacity: true, scaleX: true });
        gsap.set(item.el, { transformOrigin: item.origin || 'left' });

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            x: 40,
            opacity: 0,
            scaleX: 0,
          },
          {
            duration: timing,
            x: 0,
            opacity: 1,
            scaleX: 1,
            ease,
            delay,
          },
        );

        break;

      case 'moveTitle':
        gsap.killTweensOf(item.el);

        gsap.fromTo(
          item.el,
          {
            duration: timing,
            x: '-50%',
            y: '-50%',
            top: '50%',
            left: '50%',
            opacity: 1,
          },
          {
            duration: timing,
            x: '0%',
            y: '0%',
            top: 0,
            left: 0,
            ease,
            delay,
          },
        );

        break;

      case 'count':
        // eslint-disable-next-line no-param-reassign
        item.el.innerHTML = `0${item.measure}`;

        gsap.to(
          countValue,
          {
            duration: 1,
            value: count,
            roundProps: 'value',
            delay,
            onUpdate: () => {
              if (countValue.value !== count) {
                // eslint-disable-next-line no-param-reassign
                item.el.innerHTML = `${countValue.value}${item.measure}`;
              }
            },
          },
        );

        break;

      case 'curtain':
        gsap.to(
          item.el.querySelector('.curtain'),
          {
            duration: item.timing || 0.3,
            height: 6,
            delay,
            ease,
          },
        );

        gsap.to(
          item.el.querySelector('.curtain'),
          {
            duration: item.timing || 0.3,
            width: 25,
            delay: delay + (item.timing || 0.3),
            ease,
          },
        );

        break;

      case 'distort':
        // eslint-disable-next-line no-case-declarations
        const distortMap = item.el.querySelector('feDisplacementMap');
        // eslint-disable-next-line no-case-declarations
        const image = item.el.querySelector('image');
        if (!distortMap || !image) break;

        gsap.killTweensOf(item.el, { opacity: true, y: true });
        gsap.fromTo(
          [item.el, image],
          {
            duration: timing,
            opacity: 0,
            y: 40,
          },
          {
            duration: timing,
            opacity: 1,
            y: 0,
            ease,
            delay,
          },
        );

        gsap.fromTo(
          distortMap.scale,
          { baseVal: 200 },
          {
            duration: timing,
            baseVal: 0,
            ease,
            delay,
          }
        );

        break;

      default:
        console.warn(`animation type "${item.type}"" does not exist`);
        break;
    }
  };

  parallax = (item: ParallaxItem, sT: number, windowHeight: number) => {
    const {
      el,
      shift,
      type,
      start,
      height,
      done,
      horizontal,
    } = item;

    let { y } = item;
    const pyBottom = sT + (1 - start) * windowHeight;
    const pyTop = sT - height;

    if (y >= pyTop && y <= pyBottom) {
      if (shift) {
        const amount = y - sT + height;
        const extra = 1 - start;
        const postion = windowHeight + height;
        const percent = ((amount / extra) / postion) * 100;
        const property = horizontal ? 'x' : 'y';
        y = Math.round(percent * Number(shift));

        const time = !done ? 0 : 0.5;
        // eslint-disable-next-line no-param-reassign
        item.done = true;

        gsap.killTweensOf(el);

        gsap.to(el, {
          duration: time,
          [property]: y,
          roundProps: [property],
          // ease: Circ.easeOut,
        });
      } else if (type) {
        switch (type) {
          // case 'shape':
          //   gsap.set($el, {
          //     y: !browserDetect().mobile ? sT * 0.5 : 0,
          //   })
          //   break

          case 'rotate':
            gsap.set(el, {
              rotation: sT * 0.5,
              transformOrigin: '50px 52px',
            });
            break;

          default:
            console.warn(`parallax type "${item.type}" does not exist`);
            break;
        }
      }
    }
  };

  init() {
    this.storeCache();
    this.onScroll();
  }

  events() {
    window.addEventListener('scroll', this.onScroll);
  }

  destroy() {
    this.cache.animations = undefined;
    this.cache.parallaxes = undefined;
    window.removeEventListener('scroll', this.onScroll);
  }

  activate(view?: HTMLElement) {
    if (view) {
      this.view = view;
    }

    this.init();
    this.events();
  }
}
