/* eslint-disable no-param-reassign */
import { Module } from 'vuex';
import {
  getPosts as getMarkdownPosts,
  getPost as getMarkdownPost,
} from '@/utils/posts';

import { Post } from '@/types/posts';

export type State = {
  posts?: Post[];
  post?: Post
}

const SET_POSTS = 'SET_POSTS';
const SET_POST = 'SET_POST';

const module: Module<State, any> = {
  state: {
    posts: undefined,
    post: undefined,
  },
  mutations: {
    [SET_POSTS](newState, value) {
      newState.posts = value;
    },
    [SET_POST](newState, value) {
      newState.post = value;
    },
  },
  actions: {
    async getPosts({ commit }) {
      const data = await getMarkdownPosts();
      commit(SET_POSTS, data);
    },
    async getPost({ commit }, slug: string) {
      const data = await getMarkdownPost(slug);
      commit(SET_POST, data);
    },
    async setPost({ commit }, value) {
      commit(SET_POST, value);
    },
  },
  // modules: {
  // },
};

export default module;
