import { Chapter } from '@/types/chapter';

type ChapterData = {
  theme?: 'light' | 'dark';
  title: string;
  data: Chapter[];
}

const chapter1: ChapterData = {
  theme: 'light',
  title: 'ESG: the great behavioral driver of system change',
  data: [
    [
      {
        type: 'person',
        key: 'kay-swinburne',
        quote: 'The broader ESG agenda we saw gathering pace a decade ago has continued to accelerate. Businesses are working hard to consider the S and the G at the same time as they focus on the E. It has become business as usual.',
      },
      {
        type: 'avatar',
        key: 'kay-swinburne',
        src: '',
        alt: '',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Must try harder',
        description: 'The world is lagging on net zero, though financial services capital is powering the transition to sustainability.',
      },
      {
        type: 'prediction',
        title: 'Every decision is an ESG decision',
        description: 'ESG criteria are embedded in lending and investment; impact potential drives capital allocation.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'S and G, not just E',
        description: 'The backlash on social issues is over: customers want justice and equality. Alternatives aren’t alternative — investors have doubled their exposure to asset classes such as renewables.',
      },
      {
        type: 'prediction',
        title: 'Alternatives aren’t alternative',
        description: 'investors have doubled their exposure to asset classes such as renewables.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Crisis is global',
        description: 'Working together, particularly on tax policy, governments are finally moving forward.',
      },
    ],
  ],
};

const chapter2: ChapterData = {
  theme: 'dark',
  title: 'The power shift: democratized data moves power around the financial system',
  data: [
    [
      {
        type: 'person',
        key: 'fernando-morillo',
        quote: 'In 2030, we have publicly-created digital currencies that work for people living and transacting in digital spaces. Similarly, blockchain is the standard for business-to-business transactions.',
      },
      {
        type: 'avatar',
        key: 'fernando-morillo',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Know your customer',
        description: 'Digital identities enable customers to transact with trust, and ensure every brand can personalize their offer.',
      },
      {
        type: 'prediction',
        title: 'BigTech takes charge',
        description: 'The biggest brands in banking are those with consumer cerb appeal. Mobile mobilizes — access to inclusive banking services is delivered through data collection and analysis that targets the needs of all.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Mobile mobilizes',
        description: 'Access to inclusive banking services is delivered through data collection and analysis that targets the needs of all.',
      },
      {
        type: 'prediction',
        title: 'Asia still rising',
        description: 'China’s ascent has accelerated thanks to the dominance of its data-driven technology sector.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Sovereigns fight back',
        description: 'Central banks and policy makers have responded to the threat posed by private digital currencies.',
      },
    ],
  ],
};

const chapter3: ChapterData = {
  theme: 'light',
  title: 'A changed landscape: business models proliferate and adapt',
  data: [
    [
      {
        type: 'person',
        key: 'nadine-chakar',
        quote: 'Over the past decade, the disruptive technologies of our time – Web 3.0, blockchain, artificial intelligence (AI), the metaverse, quantum computing, and more – have completely reshaped our industry, its organizations, and the world at large.',
      },
      {
        type: 'avatar',
        key: 'nadine-chakar',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Metaverses in the multi-channel mix',
        description: 'New spaces provide a richer and more immersive customer experience.',
      },
      {
        type: 'prediction',
        title: 'Regulators flex their muscles',
        description: 'Regulators remain anxious to support innovation, but have proved their mettle with high-profile interventions.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'The disappearing bank',
        description: 'Banking is invisible, embedded in the underlying interaction, from grocery shopping to procurement.',
      },
      {
        type: 'prediction',
        title: 'API first',
        description: 'Concepts such as banking-as-a-service and tools such as developer portals deliver distribution advantages at scale.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Crime pays',
        description: 'Professional attackers from multiple constituencies are collaborating to share expertise, just like those they target.',
      },
    ],
  ],
};

const chapter4: ChapterData = {
  theme: 'dark',
  title: 'The data economy: data changes the economics of financial services',
  data: [
    [
      {
        type: 'person',
        key: 'stuart-spencer',
        quote: 'We have a remarkable amount of data; that enables us to really understand risk on an individual level and then to personalize pricing in a way that was never previously possible. Everyone on the planet now has a product that is right for them.',
      },
      {
        type: 'avatar',
        key: 'stuart-spencer',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Bespoke insurance',
        description: 'Every insurance policy is personalized and real-time, adjusted to the evolving risk profile of the policyholder.',
      },
      {
        type: 'prediction',
        title: 'Fair exchange',
        description: 'The data privacy challenge has eased in an era of data exchange for mutual benefit.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Powered by the data exhaust',
        description: 'AI turns data from e-commerce, social media, and e-gaming into alternative credit and risk models.',
      },
      {
        type: 'prediction',
        title: 'Bye-bye barriers to entry',
        description: 'New entrants face dramatically lower infrastructure costs to access the banking ecosystem.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Platforms take control',
        description: 'Platforms continue to cherry-pick the banks they want to work with; the rest become low-cost utility players.',
      },
    ],
  ],
};

const chapter5: ChapterData = {
  theme: 'light',
  title: 'Talent opportunity: talent becomes open too, with ecosystem-based experience a competitive differentiator',
  data: [
    [
      {
        type: 'person',
        key: 'chris-elliott',
        quote: 'Talent acquisition functions are compelled to be more innovative and agile – to embrace new channels to attract and engage talent.',
      },
      {
        type: 'avatar',
        key: 'chris-elliott',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Collaboration, not competition',
        description: 'Partnerships leverage the skills and competencies of multiple players.',
      },
      {
        type: 'prediction',
        title: 'Diversity delivers',
        description: 'A broader talent pool offers wider competencies, including emotional intelligence as well as technical expertise.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Purpose is power',
        description: 'Businesses compete for talent through engagement, shared values and investment in people.',
      },
      {
        type: 'prediction',
        title: 'Hybrid is the norm',
        description: 'Office spaces are designed around the need for engagement and socialization, rather than individual work.',
      },
    ],
    [
      {
        type: 'prediction',
        title: 'Leaders with no preconceptions',
        description: 'Even legacy businesses are recruiting senior leaders from beyond the financial services sector.',
      },
    ],
  ],
};

export default [
  chapter1,
  chapter2,
  chapter3,
  chapter4,
  chapter5,
] as ChapterData[];
