export default [
  {
    name: 'Judd Caplain',
    company: 'KPMG International',
    role: 'Global Head of Financial Services',
    image: '/assets/images/contacts/judd-caplain.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/c/judd-caplain.html',
  },
  {
    name: 'Laura Hay',
    company: 'KPMG International',
    role: 'Global Head of Insurance',
    image: '/assets/images/contacts/laura-hay.jpg',
    link: 'https://home.kpmg/xx/en/blogs/home/authors/h/laura-hay.html',
  },
  {
    name: 'Francisco Uría',
    company: 'KPMG International',
    role: 'Global Head of Banking and Capital Markets',
    image: '/assets/images/contacts/francisco-uria.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/u/francisco-uria.html',
  },
  {
    name: 'Andrew Weir',
    company: 'KPMG International',
    role: 'Global Head of Asset Management and Real Estate',
    image: '/assets/images/contacts/andrew-weir.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/w/andrew-weir.html',
  },
  {
    name: 'Glenn Mincey',
    company: 'KPMG International',
    role: 'Global Head of Private Equity',
    image: '/assets/images/contacts/glenn-mincey.jpg',
    link: 'https://home.kpmg/us/en/home/contacts/m/glenn-mincey.html',
  },
  {
    name: 'Roni Michael',
    company: 'KPMG International',
    role: 'Global Head of Innovation',
    image: '/assets/images/contacts/roni-michael.jpg',
    link: 'https://home.kpmg/xx/en/home/contacts/m/roni-michael.html',
  },
  {
    name: 'Lisa Fernihough',
    company: 'KPMG International',
    role: 'Global Head of Financial Services Advisory',
    image: '/assets/images/contacts/lisa-fernihough.jpg',
    link: 'https://home.kpmg/uk/en/home/contacts/f/lisa-fernihough.html',
  },
  {
    name: 'Robin Walduck',
    company: 'KPMG International',
    role: 'Global Head of Financial Services Tax',
    image: '/assets/images/contacts/robin-walduck.jpg',
    link: 'https://home.kpmg/uk/en/home/contacts/w/robin-walduck.html',
  },
  {
    name: 'Peter Torrente',
    company: 'KPMG International',
    role: 'Global Head of Financial Services Audit',
    image: '/assets/images/contacts/peter-torrente.jpg',
    link: 'https://home.kpmg/us/en/home/contacts/t/peter-torrente.html',
  },
];
