import { Module } from 'vuex';
import Scroll from '@/widgets/Scroll';
import Cursor from '@/widgets/Cursor';

interface AddProps {
  instance: any;
  instanceKey: 'cursor';
}

export type State = {
  cursor: Cursor | Record<string, never>;
  scroll: Scroll | Record<string, never>;
}

const module: Module<State, any> = {
  namespaced: true,
  state: {
    cursor: {},
    scroll: {},
  },
  mutations: {
    add(newState, { instance, instanceKey }: AddProps) {
      // eslint-disable-next-line
      newState[instanceKey] = instance;
    },
  }
};

export default module;
